import SliceManager from 'components/shared/SliceManager'
import delve from 'dlv'
import Error from 'next/error'
import React from 'react'
import { getDataDependencies } from 'services/api'
import fetchSingleType from 'src/lib/Strapi/fetchSingleType'

export default function Custom404({ pageData }) {
  const slices = delve(pageData, 'slices')

  if (pageData) {
    return <>{slices && <SliceManager slices={slices} />}</>
  } else {
    return <Error statusCode={404} />
  }
}

export async function getStaticProps() {
  const data = await fetchSingleType('not-found')
  const pageData = await getDataDependencies(data)

  return { props: { pageData } }
}
